import React from "react"
import SEO from "../components/seo"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import servicesStyles from "../styles/services.module.scss"
import Layout from "../components/layout"
// import { Carousel } from "react-bootstrap"



const ServicePage = ((props) => {
  return (
    <Layout>
      <SEO title="Services"/>
      {/* row 1 - services */}
      <div className={servicesStyles.layoutdiv}>
        <div className={servicesStyles.servicediv}>

          <div className={servicesStyles.serviceheads1}>
            <h1>Additional Services</h1>
          </div>

          <div className={servicesStyles.servicediv1}>
              <div className={servicesStyles.servicedivimagediv}>
                  <Img className={servicesStyles.imagetag} style={{height: '34.6vh', width: '100%', backgroundsize: 'cover', backgroundposition: 'center', backgroundrepeat: 'no-repeat'}} fluid={props.data.domesticCleaning.childImageSharp.fluid} alt="domesticCleaning"/>
              </div>
              <div className={servicesStyles.servicedivheadertag}>Domestic Cleaning Service</div>
              <div className={servicesStyles.servicedivtexttag}>
                Frequent domestic cleaning is normally carried by a maid who comes in does a through clean of the home for the day.
                All our staff are reliable, trained and have had backgrounds checked so you can be at ease knowing that a trustworthy individual is in your home.
              </div>
              <div className={servicesStyles.servicedivbuttontag}><a href="/service-domestic-cleaning">VIEW</a></div>
          </div>
          <div className={servicesStyles.servicediv2}>
              <div className={servicesStyles.servicedivimagediv}>
                  <Img className={servicesStyles.imagetag} style={{height: '34.6vh', width: '100%', backgroundsize: 'cover', backgroundposition: 'center', backgroundrepeat: 'no-repeat'}} fluid={props.data.commercialCleaning.childImageSharp.fluid} alt="commercialCleaning"/>
              </div>
              <div className={servicesStyles.servicedivheadertag}>Commercial Cleaning</div>
              <div className={servicesStyles.servicedivtexttag}>
                We offer specialised commercial or residential apartment complex cleaning services and management in and around Gauteng And Rustenburg.
                We offer commercial cleaning services for offices, schools, churches, shopping malls and other commercial business premises.
              </div>
              <div className={servicesStyles.servicedivbuttontag}><a href="/service-commercial-cleaning">VIEW</a></div>
          </div>
          <div className={servicesStyles.servicediv3}>
              <div className={servicesStyles.servicedivimagediv}>
                  <Img className={servicesStyles.imagetag} style={{height: '34.6vh', width: '100%', backgroundsize: 'cover', backgroundposition: 'center', backgroundrepeat: 'no-repeat'}} fluid={props.data.overnightBabySitting.childImageSharp.fluid} alt="overnightBabySitting"/>
              </div>
              <div className={servicesStyles.servicedivheadertag}>Overnight Baby Sitting</div>
              <div className={servicesStyles.servicedivtexttag}>
                We are there to help you through this difficult time to look after your kids while you doing other more important things that needs your attention.
                Just give us a call will be there to take care of it.
              </div>
              <div className={servicesStyles.servicedivbuttontag}><a href="/service-overnight-baby-sitting">VIEW</a></div>
          </div>
          <div className={servicesStyles.servicediv4}>
              <div className={servicesStyles.servicedivimagediv}>
                  <Img className={servicesStyles.imagetag} style={{height: '34.6vh', width: '100%', backgroundsize: 'cover', backgroundposition: 'center', backgroundrepeat: 'no-repeat'}} fluid={props.data.mobileCarWash.childImageSharp.fluid} alt="mobileCarWash"/>
              </div>
              <div className={servicesStyles.servicedivheadertag}>Mobile Car Wash</div>
              <div className={servicesStyles.servicedivtexttag}>
                We can offer you the mobile car wash as well when ladies are busy cleaning your house.
                What's more, it doesn't matter where you are as we can turn up anywhere, whenever you need us.
              </div>
              <div className={servicesStyles.servicedivbuttontag}><a href="/service-mobile-car-wash">VIEW</a></div>
          </div>

          <div className={servicesStyles.serviceheads2}>
            <h1>Additional Services</h1>
          </div>

          <div className={servicesStyles.servicediv5}>
            <div className={servicesStyles.servicedivimagediv}>
                <Img className={servicesStyles.imagetag} style={{height: '34.6vh', width: '100%', backgroundsize: 'cover', backgroundposition: 'center', backgroundrepeat: 'no-repeat'}} fluid={props.data.postConstructionResidentialCleaning.childImageSharp.fluid} alt="postConstructionResidentialCleaning"/>
            </div>
            <div className={servicesStyles.servicedivheadertag}>Post Construction/Residential Cleaning</div>
            <div className={servicesStyles.servicedivtexttag}>
            {/* We are the specialise in post construction cleaning services. */}
            When the builders have finished renovating and refurbishing your property the last thing that you will want in your new home is the smell of paint, 
            inhaling dust and seeing grime or stickers on the wall.
            </div>
            <div className={servicesStyles.servicedivbuttontag}><a href="/service-post-construction">VIEW</a></div>
          </div>
          <div className={servicesStyles.servicediv6}>
            <div className={servicesStyles.servicedivimagediv}>
                <Img className={servicesStyles.imagetag} style={{height: '34.6vh', width: '100%', backgroundsize: 'cover', backgroundposition: 'center', backgroundrepeat: 'no-repeat'}} fluid={props.data.endofTenacyCleaning.childImageSharp.fluid} alt="EndofTenacyCleaning"/>
            </div>
            <div className={servicesStyles.servicedivheadertag}>End Of Tenancy Cleaning Services</div>
            <div className={servicesStyles.servicedivtexttag}>
            Whatever your position, cleaning is a leading cause of distress and deposit dispute between landlords and tenants at the end of a tenant contract.
            When it comes to move out, many tenants neglect to do a final End of Tenancy Cleaning.
            </div>
            <div className={servicesStyles.servicedivbuttontag}><a href="/service-end-of-tenancy">VIEW</a></div>
          </div>
          <div className={servicesStyles.servicediv7}>
            <div className={servicesStyles.servicedivimagediv}>
                <Img className={servicesStyles.imagetag} style={{height: '34.6vh', width: '100%', backgroundsize: 'cover', backgroundposition: 'center', backgroundrepeat: 'no-repeat'}} fluid={props.data.carpetMattressCouchCleaning.childImageSharp.fluid} alt="CarpetMattressCouchCleaning"/>
            </div>
            <div className={servicesStyles.servicedivheadertag}>Carpet, Mattress and Couch Cleaning Services</div>
            <div className={servicesStyles.servicedivtexttag}>
            Residents can trust and rely on for all their carpet and upholstery cleaning requirements.
            Our carpet cleaning methods remove all dirt embedded deep down in the carpets and we leave your carpets fresh and dry in no time.
            </div>
            <div className={servicesStyles.servicedivbuttontag}><a href="/service-material-cleaning">VIEW</a></div>
          </div>
          <div className={servicesStyles.servicediv8}>
            <div className={servicesStyles.servicedivimagediv}>
                <Img className={servicesStyles.imagetag} style={{height: '34.6vh', width: '100%', backgroundsize: 'cover', backgroundposition: 'center', backgroundrepeat: 'no-repeat'}} fluid={props.data.cleaninglady.childImageSharp.fluid} alt="cleaninglady"/>
            </div>
            <div className={servicesStyles.servicedivheadertag}>Maid Training</div>
            <div className={servicesStyles.servicedivtexttag}>
            We offer the following training for Maids:
            Fisrst Aid, Child Care, Washing & Ironing and Personal Hygeine.
            Please enquire about when our next classes will be.
            </div>
            <div className={servicesStyles.servicedivbuttontag}><a href="/service-maid-training">VIEW</a></div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
)

export default ServicePage

export const pageQuery = graphql`
  query {
    domesticCleaning: file(relativePath: {eq: "DomesticCleaning.jpg"}) {
      childImageSharp {
        fluid(maxHeight: 1200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    commercialCleaning: file(relativePath: {eq: "CommercialCleaning.jpg"}) {
      childImageSharp {
        fluid(maxHeight: 1200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    overnightBabySitting: file(relativePath: {eq: "OvernightBabySitting.jpg"}) {
      childImageSharp {
        fluid(maxHeight: 1200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mobileCarWash: file(relativePath: {eq: "MobileCarWash.jpg"}) {
      childImageSharp {
        fluid(maxHeight: 1200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    postConstructionResidentialCleaning: file(relativePath: {eq: "PostConstructionResidentialCleaning.jpg"}) {
      childImageSharp {
        fluid(maxHeight: 1200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    endofTenacyCleaning: file(relativePath: {eq: "EndofTenacyCleaning.jpg"}) {
      childImageSharp {
        fluid(maxHeight: 1200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    carpetMattressCouchCleaning: file(relativePath: {eq: "CarpetMattressCouchCleaning.jpg"}) {
      childImageSharp {
        fluid(maxHeight: 1200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cleaninglady: file(relativePath: {eq: "cleaninglady.jpg"}) {
      childImageSharp {
        fluid(maxHeight: 1200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`